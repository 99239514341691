@font-face {
  font-family: "DomaineDisplaySemibold";
  src: local("DomaineDisplaySemibold"),
    url("./assets/fonts/DomaineDisplay-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "AvenirLTProMedium";
  src: local("AvenirLTProMedium"),
    url("./assets/fonts/Linotype-AvenirLTPro-Medium.otf") format("opentype");
}
@font-face {
  font-family: "AvenirLTProBook";
  src: local("AvenirLTProBook"),
    url("./assets/fonts/Linotype-AvenirLTPro-Book.otf") format("opentype");
}

@font-face {
  font-family: "AvenirLTProHeavy";
  src: local("AvenirLTProHeavy"),
    url("./assets/fonts/Linotype-AvenirLTPro-Heavy.otf") format("truetype");
}
@font-face {
  font-family: "AvenirLTProRoman";
  src: local("AvenirLTProRoman"),
    url("./assets/fonts/Linotype-AvenirLTPro-Roman.otf") format("truetype");
}
@font-face {
  font-family: "AvenirLTProBlack";
  src: local("AvenirLTProBlack"),
    url("./assets/fonts/Linotype-AvenirLTPro-Black.otf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "AvenirLTProBook", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --mdc-typography-headline1-font-family: "AvenirLTProHeavy";
  --mdc-typography-headline2-font-family: "AvenirLTProHeavy";
  --mdc-typography-headline3-font-family: "AvenirLTProHeavy";
  --mdc-typography-headline4-font-family: "AvenirLTProHeavy";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TODO: need to figure out a solution to this !important issue.. 
 a:-webkit-any-link {
  color: #294764 !important;
} */
